import AppLayout from '@/layout/app-layout.vue';
import AuthLayout from '@/layout/auth-layout.vue';
//import UIRoute from "@/pages/admin/ui/route";
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import RouteViewComponent from './route-view.vue';
import { useCookies } from 'vue3-cookies';
const { cookies } = useCookies();
import AuthenticationService from '@/components/helpers/AuthenticationService';
import ToastService from '@/components/helpers/ToastService';
import { UserRolesEnum } from '@mdb/core';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'create-property' }
  },
  {
    name: 'account',
    path: '/account',
    component: AppLayout,
    children: [
      {
        name: 'update-account',
        path: '',
        component: () => import('@/pages/user/AccountUpdate.vue'),
        meta: { authorize: [] }
      }
    ]
  },
  {
    name: 'property',
    path: '/property',
    component: AppLayout,
    children: [
      {
        name: 'list-property',
        path: '',
        component: () => import('@/pages/property/PropertyList.vue'),
        meta: { authorize: [] }
      },
      {
        name: 'create-property',
        path: 'create',
        component: () => import('@/pages/property/PropertyCreate.vue'),
        meta: { authorize: [] }
      },
      {
        name: 'update-property',
        path: 'edit/:id',
        component: () => import('@/pages/property/PropertyUpdate.vue'),
        meta: { authorize: [] }
      },
      {
        name: 'read-property',
        path: ':id',
        component: () => import('@/pages/property/PropertyRead.vue'),
        meta: { authorize: [] }
      }
    ]
  },
  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    children: [
      {
        name: 'user',
        path: 'user',
        component: RouteViewComponent,
        children: [
          {
            name: 'list-user',
            path: '',
            component: () => import('@/pages/user/UserList.vue'),
            meta: { authorize: [UserRolesEnum.ADMIN] }
          },
          {
            name: 'create-user',
            path: 'create',
            component: () => import('@/pages/user/UserCreate.vue'),
            meta: { authorize: [UserRolesEnum.ADMIN] }
          },
          {
            name: 'update-user',
            path: 'edit/:id',
            component: () => import('@/pages/user/UserUpdate.vue'),
            meta: { authorize: [] }
          },
          {
            name: 'read-user',
            path: ':id',
            component: () => import('@/pages/user/UserRead.vue'),
            meta: { authorize: [] }
          }
        ]
      },
      {
        name: 'pages',
        path: 'pages',
        component: RouteViewComponent,
        children: [
          {
            name: 'faq',
            path: 'faq',
            component: () => import('@/pages/admin/pages/FaqPage.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('@/pages/auth/Login.vue')
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('@/pages/auth/Signup.vue')
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('@/pages/auth/RecoverPassword.vue')
      },
      {
        name: 'reset-password',
        path: 'reset-password',
        component: () => import('@/pages/auth/ResetPassword.vue')
      },
      {
        path: '',
        redirect: { name: 'login' }
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('@/pages/pages/404.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes
});

router.beforeEach((to, from) => {
  const authorize: [string] | undefined = to.meta?.authorize as [string] | undefined;

  const loginPage = {
    name: 'login',
    // save the location we were at to come back later
    query: { redirect: to.fullPath }
  };

  const homePage = {
    name: 'home'
  };

  if (authorize && !AuthenticationService.isConnected()) {
    return loginPage;
  }
  if (authorize && authorize?.length > 0 && !AuthenticationService.hasRoles(authorize)) {
    AuthenticationService.logout();
    return loginPage;
  }
  // if connected and try to login redirect to home page
  if (AuthenticationService.isConnected() && to.name == 'login') {
    return homePage;
  }
});
router.afterEach((to, from) => {
  ToastService.displayToast();
});
export default router;
