
  import { defineComponent, provide } from 'vue'
  import { ApolloClients } from '@vue/apollo-composable'
  //import { apolloClients } from 'src/extensions/apollo/boot'

  export default defineComponent({
    name: 'App',
    // setup() {
    //   provide(ApolloClients, apolloClients)
    // },
  })
