import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client/core';
import { ApolloClients } from '@vue/apollo-composable';
import { createApolloProvider } from '@vue/apollo-option';
import vClickOutside from 'click-outside-vue3';
import { createApp, h, provide } from 'vue';
import { createGtm } from 'vue-gtm';
import { createI18n } from 'vue-i18n';
import Toast, { PluginOptions } from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';
import { VuesticPlugin } from 'vuestic-ui';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuesticGlobalConfig from './services/vuestic-ui/global-config';
// import { InMemoryCache } from 'apollo-cache-inmemory'
import VueCookies from 'vue3-cookies';
import { useCookies } from 'vue3-cookies';
import store from './store';
import('../overrides.css');
//Vue.config.productionTip = false;
const httpLink = new HttpLink({
  // uri: process.env.VUE_APP_API_URL,
  uri: process.env.VUE_APP_API_URL
});
// add token to every request
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const { cookies } = useCookies();
  //const route = useRoute();
  const token = cookies.get('jwt');
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null
    }
  });
  return forward(operation);
});
// Create the apollo client
const apolloClient = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true
});

// Create a provider
const apolloProvider = createApolloProvider({
  defaultClient: apolloClient
});

const i18nConfig = {
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: {
    fr: require('@/i18n/fr.json')
  }
};

const app = createApp({
  setup() {
    provide(ApolloClients, {
      default: apolloClient
    });
  },
  render: () => h(App)
});
app.use(store);
app.use(router);
app.use(apolloProvider);
app.use(vClickOutside);
app.use(VueCookies);

// for paging
app.config.globalProperties.$requestedPage = process.env.VUE_APP_REQUESTED_PAGE;
app.config.globalProperties.$limit = process.env.VUE_APP_LIMIT;
app.config.globalProperties.$contactWebsite = process.env.VUE_APP_CONTACT_WEBSITE;

//app.config.globalProperties.foo = "bar";

//TOADTER
const options: PluginOptions = {
  // You can set your default options here
};

app.use(Toast, options);

if (process.env.VUE_APP_GTM_ENABLED === 'true') {
  const gtmConfig = {
    debug: false,
    vueRouter: router
  };
  app.use(createGtm(gtmConfig));
}
app.use(createI18n(i18nConfig));
app.use(VuesticPlugin, vuesticGlobalConfig);

const diabledAll = (el, binding) => {
  if (!binding.value) return;
  const tags = ['input', 'button', 'textarea', 'select', 'chip'];
  tags.forEach(tagName => {
    const nodes = el.getElementsByTagName(tagName);
    for (let i = 0; i < nodes.length; i++) {
      nodes[i].disabled = true;
      nodes[i].tabIndex = -1;
    }
  });
};
app.directive('disable-all', diabledAll);

app.mount('#app');
