import { useCookies } from 'vue3-cookies';
const { cookies } = useCookies();
import jwt_decode from 'jwt-decode';
import { UserRolesEnum } from '@mdb/core';

interface User {
  id: string;
  roles: string[];
  firstName: string;
}

const CURRENT_USER = 'currentUser';

const JWT = 'jwt';

/**
 * Class with helpers for vue authentification
 */
export default class AuthenticationService {
  static setCookie = (jwt: string) => {
    cookies.set(JWT, jwt);
  };
  static hasValidJwt = (jwt?: string): boolean => {
    const token = jwt || cookies.get(JWT);
    if (!token) {
      return false;
    }
    try {
      const { exp }: { exp: number } = jwt_decode(token);
      const expired = Date.now() >= exp * 1000;
      if (expired) {
        cookies.remove(JWT);
        localStorage.removeItem(CURRENT_USER);
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  };
  static currentUser = (): User | null => {
    const currentUser = localStorage.getItem(CURRENT_USER);
    if (currentUser) {
      return JSON.parse(currentUser);
    }
    return null;
  };

  static isConnected = () => this.hasValidJwt() && Boolean(this.currentUser());

  /**
   *
   * @param role
   * @returns
   */
  static hasRole = (role: string | UserRolesEnum) => {
    const user = this.currentUser();
    return user && Boolean(user.roles.find((elt: string) => elt === role));
  };
  /**
   * Check if the connected user is admin
   * @returns boolean
   */
  static isAdmin = () => {
    return this.hasRole(UserRolesEnum.ADMIN);
  };

  /**
   * Check if the connected user is admin
   * @returns boolean
   */
  static isTrader = () => {
    return this.hasRole(UserRolesEnum.TRADER);
  };

  /**
   * Check if the connected user is admin
   * @returns boolean
   */
  static isAgency = () => {
    return this.hasRole(UserRolesEnum.AGENCY);
  };

  /**
   * Verify is the user has some roles
   * @param roles
   * @param all if all = true we check that the user has all the roles, if not at least one role
   * @returns
   */
  static hasRoles = (roles: [string] | UserRolesEnum[], all = false) => {
    let count = 0;
    roles.forEach((role) => (this.hasRole(role) ? count++ : ''));
    if (all) {
      return count === roles.length;
    }
    return count > 0;
  };

  static login = (token: string) => {
    cookies.set(JWT, token);
    const currentUser: User = jwt_decode(token);
    localStorage.setItem(
      CURRENT_USER,
      JSON.stringify({ id: currentUser.id, roles: currentUser.roles, firstName: currentUser.firstName })
    );
  };

  /**
   * Remove current user from local storage and remove cookie
   */
  static logout = () => {
    cookies.remove(JWT);
    localStorage.removeItem(CURRENT_USER);
  };
}
