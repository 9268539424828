<template>
  <va-dropdown class="profile-dropdown" v-model="isShown" boundary-body position="bottom" :offset="[0, 13]">
    <template #anchor>
      <span class="profile-dropdown__anchor">
        <slot />
        <va-icon class="px-2" :name="isShown ? 'angle_up' : 'angle_down'" :color="theme.primary" />
      </span>
    </template>
    <va-dropdown-content class="profile-dropdown__content">
      <va-list-item name="profile" id="profile">
        <router-link :to="{ name: 'update-account' }" class="profile-dropdown__item">
          {{ $t(`account.profile`) }}
        </router-link>
      </va-list-item>
      <va-list-item name="logout" id="logout" style="cursor:pointer">
        <span @click="handleLogout" class="profile-dropdown__item">
          {{ $t(`account.logout`) }}
        </span>
      </va-list-item>
    </va-dropdown-content>
  </va-dropdown>
</template>

<script>
import { useGlobalConfig } from 'vuestic-ui';
import { useRouter } from 'vue-router';
import AuthenticationService from '@/components/helpers/AuthenticationService';
import { ref } from 'vue';
export default {
  name: 'profile-section',
  setup: () => {
    const router = useRouter();
    const isShown = ref(false);
    const handleLogout = () => {
      AuthenticationService.logout();
      router.push({ name: 'login' });
    };
    return {
      isShown,
      handleLogout
    };
  },
  computed: {
    theme() {
      return useGlobalConfig().getGlobalConfig();
    }
  }
};
</script>
<style lang="scss">
.profile-dropdown {
  cursor: pointer;

  .va-dropdown-popper__anchor {
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    width: 8rem;
  }

  &__item {
    display: block;
    color: var(--va-gray);

    &:hover,
    &:active {
      color: var(--va-primary);
    }
  }

  .va-dropdown__anchor {
    display: inline-block;
  }
}
</style>
