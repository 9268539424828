"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRentability = void 0;
const PropertyTypeEnum_1 = require("./PropertyTypeEnum");
const lodash_1 = require("lodash");
const TVA_PERCENTAGE = 0.2;
exports.getRentability = (property) => {
    var _a, _b, _c, _d, _e, _f, _g;
    let totalBuyingPrice = 0;
    // price with agency fees
    totalBuyingPrice = +((_a = property.buyingPrice) === null || _a === void 0 ? void 0 : _a.net) * (1 + +((_b = property === null || property === void 0 ? void 0 : property.buyingPrice) === null || _b === void 0 ? void 0 : _b.agencyFees) / 100);
    // Si terrain viabilisé ajouter 8500€ au prix d’achat
    // Si terrain non viabilisé ajouter 15000€ au prix d’achat
    if (property.type === PropertyTypeEnum_1.PropertyTypeEnum.LAND) {
        if (property.serviced) {
            totalBuyingPrice += 8500;
        }
        else {
            totalBuyingPrice += 15000;
        }
    }
    let totalResalePrice = lodash_1.sum(property.details.map((detail) => {
        var _a, _b;
        let size = +property.size;
        if (detail.size) {
            size = detail.size;
        }
        return ((_b = (_a = detail.resalePrice) === null || _a === void 0 ? void 0 : _a.low) === null || _b === void 0 ? void 0 : _b.net) * size;
    }));
    if (property.type === PropertyTypeEnum_1.PropertyTypeEnum.HOUSE) {
        if (property.ground) {
            const groundPrice = +((_c = property.ground) === null || _c === void 0 ? void 0 : _c.size) * +((_e = (_d = property.ground) === null || _d === void 0 ? void 0 : _d.price) === null || _e === void 0 ? void 0 : _e.net) * (1 + +((_g = (_f = property === null || property === void 0 ? void 0 : property.ground) === null || _f === void 0 ? void 0 : _f.price) === null || _g === void 0 ? void 0 : _g.agencyFees) / 100);
            totalResalePrice += groundPrice;
        }
    }
    const tva = ((totalResalePrice - totalBuyingPrice) * TVA_PERCENTAGE) / (1 + TVA_PERCENTAGE);
    const margin = lodash_1.round(((totalResalePrice - totalBuyingPrice - tva) / totalBuyingPrice) * 100);
    return margin;
};
