"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyTypeEnum = void 0;
var PropertyTypeEnum;
(function (PropertyTypeEnum) {
    PropertyTypeEnum["FLAT"] = "FLAT";
    PropertyTypeEnum["HOUSE"] = "HOUSE";
    PropertyTypeEnum["LAND"] = "LAND";
    PropertyTypeEnum["LOCAL"] = "LOCAL";
    PropertyTypeEnum["PARKING"] = "PARKING";
    PropertyTypeEnum["BUILDING"] = "BUILDING";
    PropertyTypeEnum["CUT"] = "CUT";
})(PropertyTypeEnum = exports.PropertyTypeEnum || (exports.PropertyTypeEnum = {}));
