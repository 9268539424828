
import AuthenticationService from '@/components/helpers/AuthenticationService';
import { computed } from 'vue';
let routes = 
{
  root: {
    name: "/",
    displayName: "navigationRoutes.home"
  },
  routes: [
    {
      name: "property",
      displayName: "property.property",
      meta: {
        icon: "vuestic-iconset-tables"
      },
      children: [
        {
          name: "list-property",
          displayName: "property.list_property",
          meta: {
            icon: "vuestic-iconset-tables"
          }
        },
        {
          name: "create-property",
          displayName: "property.create_property",
          meta: {
            icon: "vuestic-iconset-tables"
          }
        }
      ]
    },
    {
      name: "admin",
      displayName: "admin.admin",
      meta: {
        icon: "vuestic-iconset-files"
      },
      disabled: true,
      children: [
        {
          name: "list-user",
          displayName: "user.list_user"
        },
        {
          name: "create-user",
          displayName: "user.create_user"
        }
      ]
    }
  ]
};

export default routes;
