import { useToast, TYPE } from 'vue-toastification';
const toast = useToast();

interface Message {
  id: string;
  message: string;
  success: boolean;
}

const SHOW_MESSAGES = 'showMessages';

/**
 * Class with helpers for vue authentification
 */
export default class ToastService {
  static addToLocalStorage = (messages: [Message]) => {
    localStorage.setItem(SHOW_MESSAGES, JSON.stringify(messages));
  };

  static displayToast = () => {
    const msg = localStorage.getItem(SHOW_MESSAGES);
    if (msg) {
      const showMessages: Message[] = JSON.parse(msg);
      showMessages.forEach((message: any) => {
        toast(message.message, {
          id: message.id,
          type: message.success ? TYPE.SUCCESS : TYPE.ERROR,
          timeout: 10000
        });
      });
      localStorage.removeItem(SHOW_MESSAGES);
    }
  };
}
