"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperationEnum = exports.OperatorEnum = void 0;
var OperatorEnum;
(function (OperatorEnum) {
    OperatorEnum["AND"] = "AND";
    OperatorEnum["OR"] = "OR";
})(OperatorEnum = exports.OperatorEnum || (exports.OperatorEnum = {}));
var OperationEnum;
(function (OperationEnum) {
    OperationEnum["EQ"] = "EQ";
    OperationEnum["IN"] = "IN";
    OperationEnum["GT"] = "GT";
    OperationEnum["LT"] = "LT";
    OperationEnum["GE"] = "GE";
    OperationEnum["LE"] = "LE";
    OperationEnum["LIKE"] = "LIKE";
})(OperationEnum = exports.OperationEnum || (exports.OperationEnum = {}));
